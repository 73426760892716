<template>
  <div class="item" :class="{ creator: source.isCreator }">
    <div class="body">
      <div class="ct">
        <div class="head">
          <div class="avatar">
            <img alt="" :src="source.avatar" />
          </div>
          <div class="name">{{ source.username }}</div>
          <div class="time">{{ source.time }}</div>
        </div>

        <div class="text" v-if="source.type === 'text' && !videoChatUrl">
          {{ source.content }}
        </div>

        <div class="text" v-if="source.type === 'text' && videoChatUrl">
          <div>Click to join the video meeting</div>
          <a target="_blank" :href="videoChatUrl">{{ videoChatUrl }}</a>
        </div>

        <div class="file" v-if="source.type === 'file'">
          <div class="icon">
            <Icon type="ios-document" />
          </div>
          <div>
            <div class="name">{{ source.media.filename }}</div>
            <div class="size-box">
              <div class="size">{{ sizeTostr(source.media.size) }}</div>
              <Icon
                @click="download(source)"
                class="download"
                type="md-download"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import downloadjs from "downloadjs";

export default {
  name: "chat-room-item",
  methods: {
    download(source) {
      const msg = this.$Message.loading({
        content: "Loading...",
        duration: 0
      });

      source.media.getContentTemporaryUrl().then(link => {
        downloadjs(link);
        msg();
      });
    },
    sizeTostr(size) {
      var data = "";
      if (size < 0.1 * 1024) {
        data = size.toFixed(2) + "B";
      } else if (size < 0.1 * 1024 * 1024) {
        data = (size / 1024).toFixed(2) + "KB";
      } else if (size < 0.1 * 1024 * 1024 * 1024) {
        data = (size / (1024 * 1024)).toFixed(2) + "MB";
      } else {
        data = (size / (1024 * 1024 * 1024)).toFixed(2) + "GB";
      }
      var sizestr = data + "";
      var len = sizestr.indexOf(".");
      var dec = sizestr.substr(len + 1, 2);
      if (dec == "00") {
        return sizestr.substring(0, len) + sizestr.substr(len + 3, 2);
      }
      return sizestr;
    }
  },
  computed: {
    videoChatUrl() {
      if (
        this.source.type === "text" &&
        this.source.content.startsWith("$channel_$video_$meeting_$room://")
      ) {
        const id = this.source.content.split(
          "$channel_$video_$meeting_$room://"
        )[1];
        return `${window.location.origin}/video?channel=${id}`;
      } else {
        return false;
      }
    }
  },
  props: {
    source: {
      type: Object
    }
  }
};
</script>

<style lang="less" scoped>
.item {
  display: flex;
  padding: 20px;

  .body {
    font-size: 16px;
    width: 560px;
    word-break: break-word;

    .ct {
      border: 1px solid #ccc;
      position: relative;
      color: #000;
      border-radius: 6px;
      padding: 0.5em 1em;

      .head {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .avatar {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background: rgba(255, 192, 203, 0.2);
          margin-right: 10px;
          img {
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        .name {
          font-size: 13px;
          margin-right: 4px;
        }
        .time {
          font-size: 12px;
          color: #7f7f7f;
        }
      }

      .text {
        margin: 0;
        font-size: 14px;
        color: #555;
      }
      .file {
        display: flex;
        align-items: center;
        border: 1px solid #e6e6e6;
        padding: 5px;
        .icon {
          color: @primary-color;
          font-size: 50px;
          margin: -10px 0;
        }
        .name {
          font-size: 14px;
          color: #666;
        }
        .size-box {
          display: flex;
          .size {
            font-size: 12px;
            color: rgb(156, 156, 156);
            margin-right: 6px;
          }
          .download {
            color: @primary-color;
            cursor: pointer;
          }
        }
      }
    }
  }

  &.creator {
    justify-content: flex-end;
    .head {
      justify-content: flex-end;
    }
  }
}
</style>
