<template>
  <div class="chat-container">
    <Spin v-if="waitingTwilioLoading" fix>Waiting to join the chat</Spin>
    <Drawer
      :width="300"
      title="Members in this group"
      :closable="false"
      v-model="usersDrawer"
      v-if="curChanneSid"
    >
      <div
        class="channel-users"
        :key="user.id"
        v-for="user in channelsKV[curChanneSid].usersKV"
      >
        <img class="avatar" :src="user.avatar" alt="" />
        <div class="info">
          <div class="name">{{ user.name }}</div>
          <div class="company">{{ user.company_name }}</div>
        </div>
      </div>
    </Drawer>

    <Modal v-model="createChannelModal" footer-hide title="Create Group">
      <Form ref="form" :model="form">
        <FormItem label="Chat name" :rules="required" prop="channel_name">
          <Input v-model="form.channel_name" />
        </FormItem>

        <FormItem
          label="My company members (You are added to this group by default)"
          prop="mySelect"
          style="text-align: left"
        >
          <Select
            not-found-text="No more other company members"
            v-model="form.mySelect"
            multiple
          >
            <Option v-for="item in myUserList" :value="item.id" :key="item.id"
              >{{ item.first_name }} {{ item.last_name }}</Option
            >
          </Select>
        </FormItem>

        <FormItem
          label="Partner company"
          :rules="required"
          prop="invited_company_id"
        >
          <Select @on-change="selectCompany" v-model="form.invited_company_id">
            <Option
              v-for="item in businessCardCompanyList"
              :value="item.id"
              :key="item.id"
              >{{ item.company_name }}</Option
            >
          </Select>
        </FormItem>

        <FormItem
          label="Partner company members"
          :rules="required"
          prop="companySelect"
        >
          <Select v-model="form.companySelect" multiple>
            <Option
              v-for="item in companyUserList"
              :value="item.id"
              :key="item.id"
              >{{ item.first_name }} {{ item.last_name }}</Option
            >
          </Select>
        </FormItem>

        <FormItem>
          <Button
            :loading="createChannelLoading"
            type="primary"
            @click="createGroup"
            >Submit</Button
          >
        </FormItem>
      </Form>
    </Modal>

    <div class="chat sc-width">
      <div class="sider">
        <div class="search-box">
          <div class="title">
            Messages
          </div>

          <div @click="createChannelModal = true" class="add-btn">+</div>
        </div>
        <div class="list">
          <div class="full">
            <div
              @click="chooseChannel(item)"
              :class="['item', { active: curChanneSid === item.sid }]"
              :key="item.sid"
              v-for="item in channels"
            >
              <!-- <Badge :count="item.newMessageCount"></Badge> -->

              <div class="channel-box">
                <div class="channel-head">
                  <div class="dot" v-if="item.newMessageCount !== 0"></div>
                  <div class="channel-name">
                    {{ item.channel_name }}
                  </div>
                  <div class="channel-time">
                    {{ formatTime(item.lastMessageTime) }}
                  </div>
                </div>
                <div class="company">
                  {{ item.company }}
                </div>

                <div class="user-list" v-if="Object.keys(item.usersKV)[0]">
                  <Icon class="icon" type="md-person" />
                  <div class="user-name">
                    {{ item.usersKV[Object.keys(item.usersKV)[0]].name }} •
                    {{ item.usersKV[Object.keys(item.usersKV)[0]].job_title }}
                  </div>
                </div>

                <div class="user-list" v-if="Object.keys(item.usersKV)[1]">
                  <Icon class="icon" type="md-person" />
                  <div class="user-name">
                    {{ item.usersKV[Object.keys(item.usersKV)[1]].name }} •
                    {{ item.usersKV[Object.keys(item.usersKV)[1]].job_title }}
                  </div>
                </div>

                <div
                  v-if="Object.keys(item.usersKV).length - 2 > 0"
                  class="more"
                >
                  +{{ Object.keys(item.usersKV).length - 2 }} more
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="empty" v-if="!curChanneSid">
          <Icon class="icon" type="ios-chatboxes-outline" />
          <!-- <div class="text">Choose a chat</div> -->
        </div>
        <div v-else class="chat-box">
          <div class="top-bar">
            <div class="top-bar-left">
              <span class="channel-name">{{
                channelsKV[curChanneSid].channel_name
              }}</span>

              <!-- <Icon @click="deleteChannel" class="setting" type="ios-trash" /> -->

              <!-- <Icon
                @click="editChannelNameModal = true"
                class="setting"
                type="ios-create-outline"
              /> -->
            </div>
            <div class="top-bar-right">
              <Icon
                @click="usersDrawer = true"
                class="users"
                type="md-contacts"
              />
            </div>
          </div>
          <div class="message">
            <Spin fix v-if="queryHistoryMessageLoading">Loading</Spin>
            <virtual-list
              ref="vsl"
              class="message-list"
              :data-key="'id'"
              :data-sources="messages"
              :data-component="itemComponent"
              @resized="onItemRendered"
              @totop="onTotop"
            >
              <div slot="header" v-show="overflow" class="header">
                <div v-show="channelsKV[curChanneSid].hasPrevPage">loading</div>
              </div>
            </virtual-list>
          </div>

          <div class="chat-area">
            <div class="bar">
              <Tooltip content="Send a file" placement="top">
                <div @click="$refs.sendFile.click()">
                  <input
                    @change="sendFile($event)"
                    v-show="false"
                    type="file"
                    ref="sendFile"
                  />
                  <Icon class="icon mr-10" type="ios-folder" />
                </div>
              </Tooltip>

              <Tooltip content="Start a meeting" placement="top">
                <Icon @click="send('video')" class="icon" type="ios-videocam" />
              </Tooltip>
            </div>

            <input
              v-on:keydown.enter.exact.prevent="send"
              class="textarea"
              v-model="chat"
              placeholder="Type a message..."
            />

            <div class="send" @click="send">Send</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Vue from "vue";
import { twilioIdPrefix } from "@/config";
import VirtualList from "vue-virtual-scroll-list";
import { nanoid } from "nanoid";
import { mapState } from "vuex";
import api from "@/api";
import Item from "./Item";
import moment from "moment";
const {
  _queryBusinessCardCompanyList,
  _queryCompanyUser,
  _queryMemberList,
  _createChat,
  _sendMessage
} = api;
const listenersFn = {};
export default {
  components: {
    "virtual-list": VirtualList
  },
  computed: {
    ...mapState("user", ["user_id"]),
    ...mapState("chat", [
      "waitingTwilioLoading",
      "client",
      "$channelsKV",
      "curChanneSid",
      "channelsKV",
      "channelsSort"
    ]),
    channels() {
      return this.channelsSort.map(item => this.channelsKV[item]);
    }
  },

  watch: {
    waitingTwilioLoading(val, oldVal) {
      if (oldVal && !val) {
        console.log(Object.keys(this.$channelsKV));
        this.initChannelList();
      }
    }
  },

  data() {
    return {
      itemComponent: Item,
      overflow: false,
      required: [
        {
          required: true,
          message: "Please fill"
        }
      ],
      form: {
        channel_name: "",
        mySelect: [],
        invited_company_id: null,
        companySelect: []
      },
      businessCardCompanyList: [],
      myUserList: [],
      companyUserList: [],
      createChannelLoading: false,
      createChannelModal: false,
      usersDrawer: false,
      queryHistoryMessageLoading: false,
      chat: "",
      messages: [],
      hasPrevPage: false,
      $queryMessageObj: null
    };
  },
  created() {
    const company = this.$route.query.company;
    const user = this.$route.query.user;
    if (company) {
      this.createChannelModal = true;
      this.form.invited_company_id = Number(company);
      this.selectCompany(company, user);
    }

    _queryBusinessCardCompanyList({ limit: 999 }).then(response => {
      const { data } = response;
      this.businessCardCompanyList = data.data;
    });
    _queryMemberList({ limit: 999 }).then(response => {
      const { data } = response;
      this.myUserList = data.data.filter(
        item => String(item.id) !== String(this.user_id)
      );
    });

    if (!this.waitingTwilioLoading) {
      this.initChannelList();
    }
  },
  beforeDestroy() {
    this.$store.commit("chat/chooseCurChannel", null);
    // remove listener
    Object.keys(this.$channelsKV).forEach(key => {
      const $channel = this.$channelsKV[key];
      $channel.removeListener("messageAdded", listenersFn[$channel.sid]);
    });
  },
  methods: {
    initChannelList() {
      Object.keys(this.$channelsKV).map(key => {
        const $channel = this.$channelsKV[key];
        const fn = message => {
          console.log(
            "receive message",
            message,
            "||",
            "is current channel",
            $channel.sid === this.curChanneSid
          );
          if ($channel.sid === this.curChanneSid) {
            $channel.setAllMessagesConsumed();
            this.messages.push(this.createMessage(message));
            this.$nextTick(() => {
              this.setVirtualListToBottom();
            });
          }
        };
        listenersFn[$channel.sid] = fn;
        $channel.on("messageAdded", fn);
      });

      const sid = this.$route.query.sid;
      if (sid) {
        this.chooseChannel({ sid });
      }
    },
    onTotop() {
      if (!this.$queryMessageObj) {
        return;
      }
      if (
        !this.queryHistoryMessageLoading &&
        this.$queryMessageObj &&
        this.$queryMessageObj.hasPrevPage
      ) {
        this.queryHistoryMessageLoading = true;
        this.$queryMessageObj
          .prevPage()
          .then(res => {
            this.messages = [
              ...res.items.map(message => {
                return this.createMessage(message);
              }),
              ...this.messages
            ];
            this.$queryMessageObj = res;
          })
          .finally(() => {
            this.queryHistoryMessageLoading = false;
          });
      }
    },
    onItemRendered() {
      const vsl = this.$refs.vsl;
      if (vsl) {
        this.overflow = vsl.getScrollSize() > vsl.getClientSize();
      }
    },
    selectCompany(id, userId) {
      this.form.companySelect = [];
      _queryCompanyUser({ id, limit: 999 }).then(response => {
        const { data } = response;
        this.companyUserList = data.data;
        if (this.companyUserList.indexOf(userId) > -1) {
          this.form.companySelect = [userId];
        } else {
          this.form.companySelect = this.companyUserList.map(item =>
            Number(item.id)
          );
        }
      });
    },
    createGroup() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.createChannelLoading = true;
          const users = [
            this.user_id,
            ...this.form.mySelect,
            ...this.form.companySelect
          ];
          const partnerCompany = this.businessCardCompanyList.find(
            item => String(item.id) === String(this.form.invited_company_id)
          );
          this.client
            .createChannel({
              isPrivate: true,
              friendlyName: this.form.channel_name,
              attributes: {
                company: partnerCompany?.company_name
              }
            })
            .then($channel => {
              _createChat({
                channel_sid: $channel.sid,
                invited_company_id: this.form.invited_company_id,
                channel_name: this.form.channel_name,
                users: [...this.form.mySelect, ...this.form.companySelect]
              });

              const arr = users.map(id => {
                return $channel.add(`${twilioIdPrefix}${id}`); // invite user
              });
              Promise.all(arr).then(() => {
                this.$store.commit("chat/setNewChannel", {
                  sid: $channel.sid,
                  name: this.form.channel_name,
                  company: partnerCompany?.company_name
                });
                this.$store.commit("chat/set$channelsKV", $channel);
                this.$store.dispatch("chat/createGroup", $channel);
                const fn = message => {
                  if ($channel.sid === this.curChanneSid) {
                    $channel.setAllMessagesConsumed();
                    this.messages.push(this.createMessage(message));
                    this.$nextTick(() => {
                      this.setVirtualListToBottom();
                    });
                  }
                };
                listenersFn[$channel.sid] = fn;
                $channel.on("messageAdded", fn);
                this.createChannelLoading = false;
                this.createChannelModal = false;

                this.chooseChannel({ sid: $channel.sid });
              });
            });
        }
      });
    },
    deleteChannel() {
      const $channel = this.$channelsKV[this.curChanneSid];
      $channel.delete();
    },
    createMessage(message) {
      message
        .getMember()
        .then(member => {
          return member.getUser();
        })
        .then(user => {
          baseObj.username = user.attributes.name;
          baseObj.avatar = user.attributes.avatar;
        });
      let baseObj = {
        id: message.sid,
        isCreator: `${twilioIdPrefix}${this.user_id}` === message.author,
        time: this.formatTime(message.dateCreated),
        avatar: "",
        username: ""
      };
      if (message.type === "media") {
        baseObj = {
          ...baseObj,
          type: "file",
          media: message.media
        };
      }
      if (message.type === "text") {
        baseObj = {
          ...baseObj,
          type: "text",
          content: message.body
        };
      }
      return baseObj;
    },
    chooseChannel(item) {
      if (item.sid === this.curChanneSid) {
        return;
      }
      // resset
      this.$queryMessageObj = null;
      this.messages = [];
      this.hasPrevPage = false;
      // resset
      this.$store.commit("chat/chooseCurChannel", item.sid);
      const $channel = this.$channelsKV[this.curChanneSid];
      this.$store.commit("chat/readCurChannel");
      $channel.setAllMessagesConsumed();
      this.queryHistoryMessageLoading = true;
      $channel
        .getMessages()
        .then(res => {
          if (this.curChanneSid !== $channel.sid) {
            return;
          }
          this.$queryMessageObj = res;
          this.hasPrevPage = res.hasPrevPage;
          this.messages = res.items.map(message => {
            return this.createMessage(message);
          });
          this.$nextTick(() => {
            this.setVirtualListToBottom();
          });
        })
        .finally(() => {
          this.queryHistoryMessageLoading = false;
        });
    },
    async sendFile(e) {
      const file = e.target.files[0];
      if (file) {
        if (file.size > 20 * 1024 * 1024) {
          this.$Message.error("File size cannot exceed 20MB");
        } else {
          const dt = new FormData();
          dt.append("file", file);
          const $channel = this.$channelsKV[this.curChanneSid];
          $channel.sendMessage(dt);
          const msg = this.$Message.loading({
            content: "Sending...",
            duration: 0
          });
          setTimeout(msg, 3000);
          e.target.value = "";
          _sendMessage({
            channel_sid: this.curChanneSid,
            type: 1,
            message: ""
          });
        }
      }
    },
    formatTime(dateObj) {
      if (!dateObj) {
        return "";
      }
      const today = moment().format("YYYY-MM-DD");
      if (today === moment(dateObj, "DD/MM/YYYY").format("YYYY-MM-DD")) {
        return moment(dateObj).format("HH:mm");
      } else {
        return moment(dateObj, "DD/MM/YYYY").format("YYYY/MM/DD");
      }
    },
    send(type) {
      let chatText = this.chat;
      let messageType = 0;
      if (type === "video") {
        chatText = `$channel_$video_$meeting_$room://${nanoid()}`;
        messageType = 2;
      }
      const $channel = this.$channelsKV[this.curChanneSid];
      $channel.sendMessage(chatText);
      this.chat = "";
      _sendMessage({
        channel_sid: this.curChanneSid,
        message: chatText,
        type: messageType
      });
    },
    setVirtualListToBottom() {
      if (this.$refs.vsl) {
        this.$refs.vsl.scrollToBottom();
      }
    }
  }
};
</script>
<style lang="less" scoped>
.channel-users {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  .avatar {
    width: 30px;
    height: 30px;
    background: #eee;
    border-radius: 50%;
    margin-right: 10px;
  }
  .info {
    .name {
      color: #000;
      font-weight: bold;
    }
    .company {
      color: #666;
      font-size: 12px;
    }
  }
}
.chat-container {
  /*height: 100%;*/
  /*display: flex;*/
  /*justify-content: center;*/
  /*flex: 1;*/
  /*overflow: hidden;*/
  /*margin-top:330px;*/
  /*background: ;*/
  .chat {
    /*max-width: 1130px;*/
    margin: 160px auto 0 auto;
    justify-content: center;
    flex: 1;
    overflow: hidden;
    height: 100%;
    display: flex;
    box-shadow: 0 2px 16px grey, 0 0 1px grey, 0 0 1px grey;
    height: 700px;
    .sider {
      width: 300px;
      height: 100%;
      overflow: hidden;
      border-right: 1px solid #ccc;
      background: #fff;
      display: flex;
      flex-direction: column;
      .search-box {
        padding: 0 20px;
        height: 60px;
        border-bottom: 1px solid #ccc;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title {
          font-size: 18px;
          color: #333;
          font-weight: bold;
        }
        .add-btn {
          border-radius: 3px;
          background: #fde5d2;
          color: @primary-color;
          font-weight: bold;
          padding: 0px 7px;
          font-size: 17px;
          cursor: pointer;
        }
      }
      .list {
        height: 100%;
        overflow-y: scroll;
        position: relative;
        .full {
          // height: 100%;
          .item {
            padding: 15px 20px;
            border-bottom: 1px solid #ccc;
            display: flex;
            align-items: center;
            cursor: pointer;
            &.active {
              background: #e5e5e5;
            }
            .channel-box {
              width: 100%;
              .channel-head {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;
                .dot {
                  position: absolute;
                  width: 8px;
                  height: 8px;
                  border-radius: 50%;
                  background: #d74a4a;
                  left: -13px;
                }
                .channel-name {
                  color: #333;
                  font-size: 16px;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                  font-weight: bold;
                }
                .channel-time {
                  color: #7f7f7f;
                  font-size: 12px;
                  white-space: nowrap;
                }
              }
              .company {
                color: #7f7f7f;
                font-size: 12px;
              }
              .user-list {
                .icon {
                  margin-right: 4px;
                  font-size: 12px;
                }
                .user-name {
                  font-size: 12px;
                }
                display: flex;
                align-items: center;
                color: #7f7f7f;
                font-size: 12px;
              }
              .more {
                color: #7f7f7f;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
    .content {
      flex: 1;
      display: flex;
      .empty {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon {
          font-size: 80px;
          color: #ccc;
        }
        .text {
          font-size: 20px;
        }
      }
      .chat-box {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .top-bar {
          margin: 0 20px;
          height: 60px;
          border-bottom: 1px solid #ccc;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .top-bar-left {
            .channel-name {
              font-size: 18px;
              color: #333;
              font-weight: bold;
              margin-right: 10px;
            }
            .setting {
              font-size: 22px;
              cursor: pointer;
            }
          }
          .top-bar-right {
            .users {
              font-size: 22px;
              cursor: pointer;
              border: 1px solid @primary-color;
              border-radius: 50%;
              color: @primary-color;
              padding: 4px;
            }
          }
        }
        .message {
          flex: 1;
          position: relative;
          .header {
            display: flex;
            justify-content: center;
          }
          .message-list {
            // padding: 20px;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            overflow-y: auto;
          }
        }
        .chat-area {
          border-radius: 6px;
          display: flex;
          align-items: center;
          height: 50px;
          margin: 10px 20px 30px 20px;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.149019607843137);
          border: 1px solid #ccc;
          .bar {
            font-weight: bold;
            font-size: 24px;
            padding: 0 20px;
            border-right: 1px solid #ccc;
            height: 100%;
            display: flex;
            align-items: center;
            margin-right: 10px;
            .icon {
              cursor: pointer;
              color: @primary-color;
              border: 1px solid @primary-color;
              padding: 4px;
              border-radius: 50%;
            }
          }
          .textarea {
            // height: 200px;
            flex: 1;
            overflow: auto;
            // min-height: 200px;
            resize: none;
            border: none;
            outline: none;
            box-shadow: none;
          }
          .send {
            background: @primary-color;
            color: #fff;
            width: 88px;
            height: 35px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 10px;
            font-weight: bold;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
<style scope>
  .ivu-form .ivu-form-item-label{
    text-align: left !important;
    line-height: 22px;
  }
</style>
